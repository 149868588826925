import React from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: 0,
  width: '100%',
  padding: theme.spacing(10, 0, 5),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(20, 0, 10),
  },
}));

// ----------------------------------------------------------------------

const LogoOnlyLayout = () => (
  <>
    <HeaderStyle>
      <Logo />
    </HeaderStyle>
    <Outlet />
  </>
);

export default LogoOnlyLayout;
