const en = {
  drivers: 'Drivers',
  currentweek: 'Current Week',
  donthaveaccount: "Don't have an account?",
  donthavecode: "Don't have a code? ",
  resendcode: 'Resend code',
  getstarted: 'Get started',
  welcomeback: 'Welcome Back!',
  signin: 'Sign in',
  enterdetails: 'Please enter your credentials to login',
  enter_valid_username: 'Please enter correct username',
  enter_valid_password: 'Please enter correct password',
  manual_logout_message: 'You have been successfully logged out',
  auto_logout_message: 'Your session expired. Please sign in again to continue',
  username: `Username`,
  password: 'Password',
  confirmpassword: 'Confirm Password',
  changepassword: 'Change Password',
  oldpassword: 'Old Password',
  newpassword: 'New Password',
  confirmnewpassword: 'Confirm New Password',
  rememberme: 'Remember me',
  forgotpassword: 'Forgot your Password?',
  forgotpassworddesc:
    "Please enter the email address associated with your account, and we'll email you a link to reset your password.",
  resetpassword: 'Reset Password',
  requestsent: 'Please check your email!',
  requestsentdesc:
    "We've emailed a 6-digit confirmation code to {{key1}}, please enter the code in below box to verify your email.",
  newotpsent: 'Confirmation Code has been resent to your email address',
  login: 'Login',
  or: 'or',
  and: 'and',
  code: 'Code',
  register: 'Register',
  alreadyhaveaccount: 'Already have an account?',
  agreement: 'By registering, I agree to Matchpoint Solutions',
  termservice: 'Terms of Service',
  privacypolicy: 'Privacy Policy',
  first_name: 'First Name',
  last_name: 'Last Name',
  nick_name: 'Nick Name',
  mobile: 'Mobile Number',
  phone: 'Personal Phone Number',
  email: 'Email',
  email_address: 'Email Address',
  dashboard: 'Dashboard',
  operations: 'Operations',
  planning: 'Planning',
  user: 'User',
  users: 'Users',
  role: 'Settings',
  roles: 'Settings',
  profile: 'Profile',
  logout: 'Logout',
  list: 'List',
  add: 'Add',
  new: 'New',
  save: 'Save',
  edit: 'Edit',
  delete: 'Delete',
  savechanges: 'Save Changes',
  cancel: 'Cancel',
  back: 'Back',
  upload: 'Upload',
  photo: 'Photo',
  theme: 'Theme',
  language: 'Language',
  settings: 'Settings',
  themepreference: 'Theme preference',
  languagepreference: 'Language preference',
  allowed: 'Allowed',
  rolename: 'Settings Name',
  roledesc: 'Settings description',
  createuser: 'Create a new user',
  permissions: 'Permissions',
  permissiondesc: 'Please select permissions',
  payment: 'Payment',
  paymentMessage: {
    success: 'Transaction completed successfully!',
    error: 'Something wend wrong, Please try again!',
    cancel: 'Transaction cancelled by user.',
  },
  status: 'Status',
  transactionid: 'Transaction Id',
  validation: {
    required: 'is required',
    minlength: 'must be atleast {{key1}} character long',
    maxlength: 'should not be greater than {{key1}} characters long',
    password: 'Password must have atleast one Capital, one special character, one number',
    match: 'must match',
    valid: 'Please enter valid',
    onlyalpha: 'should contains alphabets only',
    username: 'Username allowed alphanumeric, . _ + - @ characters',
    newpassword: 'Old and New password cannot be the same.',
    phonenumber: 'Please enter phone number in +XXX XXXX XXXX format',
    number: '{{key1}} must be a number',
    datetime: 'Please enter valid date and time',
  },
  message: {
    create: 'created successfully!',
    save: 'saved successfully!',
    delete: 'deleted successfully!',
    change: 'changed successfully!',
    update: 'updated successfully!',
    delete_confirm: 'Are you sure you want to delete this {{key1}}?',
    delete_confirm_desc: 'This will delete the {{key1}} from the database and all of its data.',
    activeconfirm: `Are you sure you want to {{key1}} this {{key2}}?`,
    activeconfirmdesc: `This action will make the {{key1}} {{key2}}`,
    maxsize: 'max size of',
    logout: 'Logged out successfully!',
    enable: 'enabled successfully!',
    disable: 'disabled successfully!',
    deactivate: 'deactivated successfully!',
    uploaded: 'uploaded successfully!',
    uploadFail: 'Uploading fail for {{key1}} file',
    use_customer: 'Are you sure you want to use this customer?',
    do_not_use_customer: 'Are you sure you do not want to use this customer?',
    rate_confirmation_document_upload: 'Upload Rate confirmation document',
    booked: 'booked successfully!',
    notempty: 'Should not be empty',
    archive: 'archived successfully!',
    message: 'message sent successfully!'
  },
  submit: 'Submit',
  currentPassword: 'Current Password',
  userListSearchText: 'Search by username or location',
  roleListSearchText: 'Search by name',
  deactivate: 'Deactivate',
  enable: 'Enable',
  disable: 'Disable',
  createnewuser: 'Create a New User',
  edituser: 'Edit User',
  createrole: 'Create a New Settings',
  editrole: 'Edit Settings',
  title: 'Title',
  location: 'Location',
  is_driver: 'Driver',
  lastlogin: 'Last Login',
  disabledesc: 'Deactivating will remove user access to this platform',
  deleterolealert:
    'This role has {{key1}} assigned user(s). Please assign different roles to the associated user(s).',
  active: 'Active',
  inactive: 'Inactive',
  disabled: 'Disabled',
  deactivated: 'Deactivated',
  available: 'Available',
  selected: 'Selected',
  user_pages: {
    samsara_username: 'Samsara username',
    hos_threshold: 'Hours Of Service Threshold',
    work_phone: 'Work Phone',
    vanity_phone: 'Vanity phone',
    verify_driver: 'Please provide valid samsara driver username.',
    diabled_driver: 'Driver has been disabled on Pace',
    avatar_error: 'Please provide correct profile picture file',
    is_manual_transmission: 'Manual Transmission',
    license_number: 'License Number',
    available_days: 'Available Days',
    states: 'States',
  },
  profile_page: {
    title: 'Account Settings',
  },
  driverListSearch: 'Search driver...',
  passwordReset: {
    title: 'Set Password!',
    desc: 'You have been invited to access the platform. Use the form below to set the password',
    buttonText: 'Set Password',
  },
  general: 'General',
  customer_page: {
    add_customer_button: 'Add New Customer',
    rating: 'Hassle Rating',
    search_placeholder: 'Search...',
    do_not_use: 'Do not use',
    use: 'Use',
    dnu: 'DNU',
    dnuCustomerOnly: 'DNU customers only',
    create_customer: 'Create a New Customer',
    edit_customer: 'Customer Profile',
    type: 'Type',
    tracking_type: 'Tracking Type',
    domain: 'Domain',
    domains: 'Domains',
    logo_error: 'Please provide correct logo picture file',
    hassle_rating: 'Hassle rating',
    prefix: 'Prefix',
    editMessage: 'To edit this customer please make it active.',
  },
  customer: 'Customer',
  contacts: {
    title: 'Contacts',
    add_contact: 'Add Contact',
    search_placholder_text: 'Search...',
    contact: 'Contact',
    number: 'Phone Number',
    days_to_contact: 'Days to contact',
    hours_to_contact_start: 'Contact hours start',
    hours_to_contact_end: 'Contact hours end',
    timezone: 'Time Zone',
    add: 'Add Contact',
    edit: 'Edit Contact',
    update: 'Update Contact',
    delete: 'Delete Contact',
  },
  name: 'Name',
  address: 'Address',
  equipments: {
    search_placholder_text: 'Search...',
    title: 'Equipments List',
    type: 'Type',
    status: 'Status',
    profile: 'Equipment Profile',
    syncTitle: 'Sync New Equipment',
    sync_equipment: 'Sync Equipment',
    identification: 'Identification',
    service: 'Service',
    operational: 'Operational',
    sync_success: 'Equipment synced successfully!',
  },
  equipment_thresholds: {
    select_templates_description:
      'Selecting the template will override the existing values or you can manually set them below',
  },
  communication: {
    title: 'Communications',
    emails: 'Emails',
    sms_messages: 'SMS Messages',
    phone_call_logs: 'Phone calls logs',
    outgoing_call: 'Outgoing call',
    incoming_call: 'Incoming call',
    missed_call: 'Missed call',
    no_one_answered: 'No one answered',
    no_records_found: 'No records found !',
    you: 'You',
  },
  load: {
    new: 'New Load',
    load_create: 'Create a New Load',
    load_edit: 'Edit Load',
    trailer: 'Trailer',
    dispatcher: 'Dispatcher',
    customer: 'Customer',
    load_number: 'Customer load number',
    commodity: 'Commodity',
    weight: 'Weight(lbs)',
    revenue: 'Revenue',
    accessorial_charges: 'Accessorial Charges',
    load: 'Load',
    status: 'Status',
    profile: 'Load Profile',
    name: 'Name',
    email: 'Email',
    phone: 'Contact',
    customerProfile: 'Customer',
    view: 'View',
    check_calls: 'Check Calls',
    details: 'Load Details',
    ratings: 'Ratings',
    subRatings: 'Sub Ratings',
    overallRating: 'Overall Rating',
    brokerNotes: 'Notes',
    pickupTime: 'Pickup Time',
    notes: 'Notes',
    dropoffTime: 'Dropoff Time',
  },
  available_loads_page: {
    title: 'Available Loads',
    search: 'Search',
    booked: 'Booked/Bid placed',
    save_search: 'Save Search',
    pickup: 'Pick up',
    drop: 'Drop',
    noOfDays: 'No Of Days',
    filter: 'Filter',
    book_load: 'Book Load',
    booked_success_messgae: 'Load booked succe',
    available_load: 'Available load',
  },
  current_load_list: {
    search_placholder_text: 'Search...',
    date_range_label: 'Date Range',
    status: 'Status',
    title: 'Loads',
  },
  route: {
    route: 'Route',
    name: 'Name',
    address: 'Address',
    state: 'State',
    zipCode: 'Zip Code',
    stopInstructions: 'Stop instructions',
    appointmentTime: 'Appointment time',
    appointmentWindow: 'Appointment window (hours)',
    stopReferenceNumber: 'Stop reference number',
    stopType: 'Stop Type',
    stopReason: 'Stop Reason',
    equipment: 'Equipment',
    drivers: 'Drivers',
    addStop: 'Add Stop',
    stop: 'Stop',
    searchPlaceholder: 'Search by name...',
    searchHelpText: 'You have to use one of the search box to fill address details.',
    locationMessage: 'Please select address from one of the search option.',
    driversMaxMessage: 'Max two drivers are allowed',
    stopDelay: 'Stop Delay',
    hours: 'Hours',
    minutes: 'Minutes',
    dispatch: 'Dispatch'
  },
  tires: {
    tractor: 'Tractor Tires',
    trailer: 'Trailer Tires',
  },
  files: {
    documentsUpload: 'Upload Documents',
    select: 'Select files',
    uploadedDocuments: 'Uploaded Documents',
    btnTextUpload: 'Upload Files',
    btnTextRemove: 'Remove All',
    file: 'File',
  },
  note: {
    add: 'Add a note and type @ to mention…',
    notes: 'Notes',
    note: 'Note',
  },
  workItem: {
    title: 'Work Item',
    addItem: 'Add Item',
    updateItem: 'Update Item',
    addWorkItem: 'Add Work Item',
    updateWorkItem: 'Update Work Item',
    assignedTo: 'Assigned to',
    driver: 'Driver',
    type: 'Type',
    typeOther: 'Other type',
    reason: 'Reason for creation',
    action: 'Action needed',
    dueby: 'Due by',
    notes: 'Notes',
    assignedToMessage: 'Max 2 users are allowed',
    snooze: 'Snooze',
    dueDateMessage: 'Work item due date updated by 1 hour',
    resolved: 'Resolved',
    resolve: 'Resolve',
    remark: 'Remark',
    resolveWorkItem: 'Resolve work item',
    dueDateFilter: 'Due Date Range',
    reopen: 'Re-Open',
    driverSelectMessage:
      'When driver is selected, it will fetch current equipment and load assigned to driver.',
    driverNoEquipmentMessage: 'Driver is not assigned to any equipment.',
    delay: 'Delay',
    hours: 'Hours',
    minutes: 'Minutes',
    loadAvailableMessage: 'Load is not available.',
    planning: 'Planning',
    thresholdViolation: 'Threshold violation',
  },
  operational: {
    current_load: 'Current Load',
    current_location: 'Current Location',
    connected_equipment: 'Connected Equipment',
    next_stop: 'Next Stop',
    lane: 'Lane',
    last_updated: 'Last Updated',
    nearest_driver: 'Nearest Drivers',
  },
  assignments: {
    title: 'My Assignments',
    show_intransit: 'Show only In Transit',
    load_details: 'Load Details',
    load_reference_no: 'Load Reference Number',
    appointment_date_time: 'Appointment Date & Time',
    stops: 'Stops',
    truck: 'Truck',
    trailer: 'Trailer',
    customer: 'Customer',
    page_title: 'My Assignments List',
    profile: 'Assignment Profile',
    noData: 'No data',
  },
  dashboard_pages: {
    driver_load_planner: 'Driver Load Planner',
    load_planner: 'Load Planner',
    loads: 'Loads',
  },
  duplicateLoad: 'Duplicate Load',
  ok: 'OK',
  uber: 'Uber',
  chRobbinson:'Ch Robinson'
};

export default en;
