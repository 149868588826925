// i18n
import './locales/i18n';

// scroll bar
import 'simplebar/src/simplebar.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ConfirmContextProvider } from './contexts/ConfirmContext';
import { store } from './redux/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <SettingsProvider>
      <AuthProvider>
        <HelmetProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <ConfirmContextProvider>
                <App />
              </ConfirmContextProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </HelmetProvider>
      </AuthProvider>
    </SettingsProvider>
  </Provider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();
