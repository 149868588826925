import React, { FC, useCallback, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
// material
import { useTheme } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItemButtonProps,
  styled,
} from '@mui/material';
//
import Iconify from './Iconify';
import useLocales from '../hooks/useLocales';
import Link from './Link';
import useNavConfig from '../hooks/useNavConfig';
import { Nav } from '../layouts/dashboard/NavConfig';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props: ListItemButtonProps) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: theme.typography.fontWeightLight,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

type Props = {
  item: Nav;
  active: (path: string) => boolean;
};

const NavItem: FC<Props> = ({ item, active }) => {
  const isActiveRoot = active(item.path);
  const [open, setOpen] = useState(isActiveRoot);

  const theme = useTheme();
  const { translate } = useLocales();
  const { title, path, icon, children } = item;

  const titleTrans = translate(title);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    setOpen(active(path));
  }, [active, path]);

  const activeRootStyle = {
    color: 'primary.contrastText',
    fontWeight: 'fontWeightMedium',
    bgcolor: theme.palette.primary.main,
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <Box sx={{ ...(open && { bgcolor: 'background.neutral', borderRadius: 1 }) }}>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText primary={titleTrans} />
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);
              const childTransTitle = translate(title);
              return (
                <Link to={path} key={title}>
                  <ListItemStyle
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={childTransTitle} />
                    {isActiveSub && (
                      <Iconify
                        icon="heroicons-outline:check"
                        sx={{ width: 18, height: 18, color: 'inherit' }}
                      />
                    )}
                  </ListItemStyle>
                </Link>
              );
            })}
          </List>
        </Collapse>
      </Box>
    );
  }

  return (
    <Link to={path}>
      <ListItemStyle
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText disableTypography primary={titleTrans} />
      </ListItemStyle>
    </Link>
  );
};

const NavSection = () => {
  const { pathname } = useLocation();
  const match = useCallback(
    (path) => (path ? !!matchPath({ path, end: false }, pathname) : false),
    [pathname]
  );
  const navConfig = useNavConfig();

  return (
    <Box>
      <List disablePadding sx={{ px: 2 }}>
        {navConfig.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
};

export default NavSection;
