import React, { FC } from 'react';
import { Box, SxProps } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  src?: string;
  sx?: SxProps;
};

const SvgIconStyle: FC<Props> = ({ src, sx }) => (
  <Box
    component="span"
    sx={{
      width: 16,
      height: 16,
      display: 'inline-block',
      bgcolor: 'currentColor',
      mask: `url(${src}) no-repeat center / contain`,
      WebkitMask: `url(${src}) no-repeat center / contain`,
      ...sx,
    }}
  />
);

export default SvgIconStyle;
