import { useContext, useCallback } from 'react';
import type { DialogSettingsOptions } from '../@types/confirm';
import { ConfirmContext, defaultDialogState } from '../contexts/ConfirmContext';

let resolveCallback = (b: boolean) => {};

const useConfirm = () => {
  const dialogContext = useContext(ConfirmContext);

  const onConfirm = useCallback(() => {
    dialogContext?.setOpen(false);
    dialogContext?.setDefaultDialogSettings();
    resolveCallback(true);
  }, [dialogContext]);

  const onCancel = useCallback(() => {
    dialogContext?.setOpen(false);
    dialogContext?.setDefaultDialogSettings();
    resolveCallback(false);
  }, [dialogContext]);

  const confirm = useCallback(
    (settings: DialogSettingsOptions) => {
      dialogContext?.setOpen(true);
      dialogContext?.setDefaultDialogSettings();
      dialogContext?.setCurrentDialogSettings({ ...defaultDialogState, ...settings });
      return new Promise((res, rej) => {
        resolveCallback = res;
      });
    },
    [dialogContext]
  );

  return { confirm, onConfirm, onCancel, ...dialogContext };
};

export default useConfirm;
