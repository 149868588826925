import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import { PERMISSIONS } from '../utils/constants';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// eslint-disable-next-line
const RoleBasedLoadable = (Component, permission) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen isDashboard />}>
      <RoleBasedGuard permission={permission}>
        <Component {...props} />
      </RoleBasedGuard>
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Navigate to="/login" replace /> },
    {
      path: '',
      element: (
        <GuestGuard>
          <LogoOnlyLayout />
        </GuestGuard>
      ),
      children: [
        { path: 'login', element: <Login /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'verify-code', element: <VerifyCode /> },
      ],
    },
    {
      path: 'password-reset',
      element: (
        <AuthGuard>
          <LogoOnlyLayout />
        </AuthGuard>
      ),
      children: [{ path: '', element: <PasswordReset /> }],
    },
    // Dashboard Routes
    {
      path: '',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          children: [
            { element: <Dashboard />, index: true },
            {
              path: 'operations',
              element: <Operations />,
            },
            {
              path: 'planning',
              element: <Planning />,
            },
            {
              path: 'workitem',
              element: <WorkItem />,
            },
          ],
        },
        { path: 'profile', element: <Profile /> },
        {
          path: 'admin',
          children: [
            {
              path: 'roles',
              children: [
                {
                  path: '',
                  element: <RoleList />,
                },
                {
                  path: 'new',
                  element: <RoleCreate />,
                },
                {
                  path: ':id/edit',
                  element: <RoleEdit />,
                },
              ],
            },
            {
              path: 'users',
              children: [
                { index: true, element: <UserList /> },
                {
                  path: 'new',
                  element: <UserCreate />,
                },
                {
                  path: ':id/edit',
                  element: <UserEdit />,
                },
              ],
            },
            {
              path: 'uploads',
              element: <UploadsList />,
            },
          ],
        },
        {
          path: 'customers',
          children: [
            { index: true, element: <CustomerList /> },
            {
              path: 'new',
              element: <CustomerCreate />,
            },
            {
              path: ':id/edit',
              element: <CustomerEdit />,
            },
          ],
        },
        {
          path: 'drivers',
          children: [
            {
              index: true,
              element: <DriverList />,
            },
            {
              path: ':driverId',
              element: <Driver />,
            },
          ],
        },
        {
          path: 'equipment',
          children: [
            {
              index: true,
              element: <EquipmentList />,
            },
            {
              path: ':equipmentId',
              element: <Equipment />,
            },
          ],
        },
        {
          path: 'loads',
          children: [
            {
              path: 'current',
              children: [
                {
                  path: '',
                  element: <CurrentLoadList />,
                },
                {
                  path: 'new',
                  element: <CreateLoad />,
                },
                {
                  path: ':loadId/edit',
                  element: <LoadEdit />,
                },
              ],
            },
            {
              path: 'available',
              element: <AvailableLoadList />,
            },
          ],
        },
        {
          path: 'driver-assignments',
          children: [
            { index: true, element: <DriverAssignedLoadsList /> },
            {
              path: ':loadId',
              element: <DriverAssignment />,
            },
          ],
        },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    { path: '500', element: <Page500 /> },
    { path: '404', element: <Page404 /> },
    { path: '403', element: <Page403 /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/ForgotPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/VerifyCode')));

// USER & ROLES
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Operations = RoleBasedLoadable(
  lazy(() => import('../pages/Operations')),
  PERMISSIONS.dashboard.operations
);
const Planning = RoleBasedLoadable(
  lazy(() => import('../pages/Planning')),
  PERMISSIONS.dashboard.planning
);
const WorkItem = RoleBasedLoadable(
  lazy(() => import('../pages/WorkItem')),
  PERMISSIONS.dashboard.operations
);

const UserList = RoleBasedLoadable(
  lazy(() => import('../pages/UserList')),
  PERMISSIONS.users.list
);
const UserCreate = RoleBasedLoadable(
  lazy(() => import('../pages/UserCreate')),
  PERMISSIONS.users.create
);

const UserEdit = RoleBasedLoadable(
  lazy(() => import('../pages/UserCreate')),
  PERMISSIONS.users.edit
);

const RoleList = RoleBasedLoadable(
  lazy(() => import('../pages/RoleList')),
  PERMISSIONS.roles.list
);
const RoleCreate = RoleBasedLoadable(
  lazy(() => import('../pages/RoleCreate')),
  PERMISSIONS.roles.create
);

const RoleEdit = RoleBasedLoadable(
  lazy(() => import('../pages/RoleCreate')),
  PERMISSIONS.roles.edit
);

// DRIVER
const Driver = RoleBasedLoadable(
  lazy(() => import('../pages/Driver')),
  PERMISSIONS.drivers.read
);
const DriverList = RoleBasedLoadable(
  lazy(() => import('../pages/DriverList')),
  PERMISSIONS.drivers.list
);

// EQUIPMENT
const EquipmentList = RoleBasedLoadable(
  lazy(() => import('../pages/EquipmentList')),
  PERMISSIONS.equipment.list
);

const Equipment = RoleBasedLoadable(
  lazy(() => import('../pages/Equipment')),
  PERMISSIONS.equipment.read
);

// CUSTOMER
const CustomerList = RoleBasedLoadable(
  lazy(() => import('../pages/CustomerList')),
  PERMISSIONS.customers.list
);
const CustomerCreate = RoleBasedLoadable(
  lazy(() => import('../pages/CustomerCreate')),
  PERMISSIONS.customers.create
);

const CustomerEdit = RoleBasedLoadable(
  lazy(() => import('../pages/CustomerEdit')),
  PERMISSIONS.customers.edit
);

// LOAD
const CurrentLoadList = RoleBasedLoadable(
  lazy(() => import('../pages/CurrentLoadList')),
  PERMISSIONS.loads.currentLoadsList
);
const AvailableLoadList = RoleBasedLoadable(
  lazy(() => import('../pages/AvailableLoad')),
  PERMISSIONS.loads.availableLoadsList
);

const CreateLoad = RoleBasedLoadable(
  lazy(() => import('../pages/LoadCreate')),
  PERMISSIONS.loads.create
);

const LoadEdit = RoleBasedLoadable(
  lazy(() => import('../pages/LoadEdit')),
  PERMISSIONS.loads.edit
);

const DriverAssignedLoadsList = RoleBasedLoadable(
  lazy(() => import('../pages/DriverAssignments')),
  PERMISSIONS.myAssignments.root
);

const DriverAssignment = RoleBasedLoadable(
  lazy(() => import('../pages/DriverAssignment')),
  PERMISSIONS.myAssignments.root
);

const UploadsList = RoleBasedLoadable(
  lazy(() => import('../pages/UploadListPage')),
  PERMISSIONS.loads.currentLoadsList
);

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/PermissionDenied')));

// OTHER
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// PROFILE
const Profile = Loadable(lazy(() => import('../pages/Profile')));

//FIRST TIME PASSWORD RESET
const PasswordReset = Loadable(lazy(() => import('../pages/PasswordReset')));
