import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useConfirm from '../hooks/useConfirm';
import { ConfirmContext } from '../contexts/ConfirmContext';
import { Box, Stack, Typography } from '@mui/material';
import Transition from './SlideUpTransition';

const ConfirmDialog = () => {
  const { onCancel, onConfirm } = useConfirm();
  const confirmContext = React.useContext(ConfirmContext);

  return (
    <Dialog
      open={!!confirmContext?.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCancel}
      aria-describedby="alert-dialog-slide-description"
      sx={{ zIndex: 10000 }}
    >
      <DialogContent>
        <Stack sx={{ mb: 0.5 }} direction="row" gap={2} alignItems="flex-start">
          <Box mt={0.5}>{confirmContext?.dialogSettings?.icon}</Box>
          <Box>
            <Typography variant="h6" mb={1}>
              {confirmContext?.dialogSettings?.title}
            </Typography>
            <DialogContentText variant="body2" id="alert-dialog-slide-description">
              {confirmContext?.dialogSettings?.description}
            </DialogContentText>
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" gap={2} mt={4}>
          <Button onClick={onConfirm} variant="contained" color="error">
            {confirmContext?.dialogSettings?.confirmButtonText}
          </Button>
          <Button onClick={onCancel} variant="outlined">
            {confirmContext?.dialogSettings?.cancelButtonText}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
