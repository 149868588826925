import React, { createContext, FC, useCallback, useState } from 'react';
import type { ConfirmContextType, DialogSettings, DialogSettingsOptions } from '../@types/confirm';

export const ConfirmContext = createContext<ConfirmContextType | null>(null);

export const defaultDialogState: DialogSettings = {
  title: 'Are you sure ?',
  description: 'Do you really wanna do this action ?',
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Yes',
  icon: <div />,
};

type Props = {
  children: React.ReactNode;
};

export const ConfirmContextProvider: FC<Props> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dialogSettings, setDialogSettings] = useState<DialogSettings>(defaultDialogState);

  const setCurrentDialogSettings = useCallback((settings: DialogSettingsOptions) => {
    setDialogSettings({ ...defaultDialogState, ...settings });
  }, []);

  const setDefaultDialogSettings = useCallback(() => {
    setDialogSettings(defaultDialogState);
  }, []);

  return (
    <ConfirmContext.Provider
      value={{ open, setOpen, dialogSettings, setCurrentDialogSettings, setDefaultDialogSettings }}
    >
      {children}
    </ConfirmContext.Provider>
  );
};
