import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, SxProps } from '@mui/material';

type Props = {
  disabledLink?: boolean;
  sx?: SxProps;
  logoOnlyLayout?: boolean;
};

const Logo: FC<Props> = ({ disabledLink = false, logoOnlyLayout = true, sx }) => {
  const theme = useTheme();
  const width = logoOnlyLayout ? 175 : 125;
  const height = logoOnlyLayout ? 72 : 51;
  const PRIMARY_DARK =
    theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.common.white;

  const logo = (
    <Box sx={{ width, height, ...sx }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 229 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.8589 8.23565C19.256 5.86661 21.2302 3.98353 23.7815 2.58641C26.3327 1.18928 29.3396 0.490723 32.802 0.490723C36.8719 0.490723 40.547 1.55375 43.8272 3.67981C47.1681 5.80587 49.7801 8.84309 51.6632 12.7915C53.607 16.7399 54.5789 21.3565 54.5789 26.6412C54.5789 31.926 53.607 36.573 51.6632 40.5821C49.7801 44.5305 47.1681 47.5677 43.8272 49.6938C40.547 51.8198 36.8719 52.8829 32.802 52.8829C29.3396 52.8829 26.3327 52.1843 23.7815 50.7872C21.291 49.3901 19.3168 47.507 17.8589 45.1379V76.6643H0V1.12854H17.8589V8.23565ZM36.4467 26.6412C36.4467 23.3003 35.5355 20.7186 33.7132 18.8963C31.9516 17.0132 29.7648 16.0717 27.1528 16.0717C24.5408 16.0717 22.3236 17.0132 20.5013 18.8963C18.7397 20.7794 17.8589 23.361 17.8589 26.6412C17.8589 29.9822 18.7397 32.5942 20.5013 34.4773C22.3236 36.3604 24.5408 37.3019 27.1528 37.3019C29.7648 37.3019 31.9516 36.3604 33.7132 34.4773C35.5355 32.5335 36.4467 29.9214 36.4467 26.6412Z"
          fill={PRIMARY_DARK}
        />
        <path
          d="M59.0152 26.6412C59.0152 21.3565 59.9567 16.7399 61.8398 12.7915C63.7836 8.84309 66.3957 5.80587 69.6758 3.67981C73.0168 1.55375 76.7222 0.490723 80.7921 0.490723C84.3153 0.490723 87.3525 1.18928 89.9038 2.58641C92.4551 3.98353 94.4292 5.86661 95.8264 8.23565V1.12854H113.685V52.245H95.8264V45.1379C94.4292 47.507 92.4247 49.3901 89.8127 50.7872C87.2614 52.1843 84.2545 52.8829 80.7921 52.8829C76.7222 52.8829 73.0168 51.8198 69.6758 49.6938C66.3957 47.5677 63.7836 44.5305 61.8398 40.5821C59.9567 36.573 59.0152 31.926 59.0152 26.6412ZM95.8264 26.6412C95.8264 23.361 94.9152 20.7794 93.0929 18.8963C91.3313 17.0132 89.1445 16.0717 86.5325 16.0717C83.8597 16.0717 81.6425 17.0132 79.8809 18.8963C78.1193 20.7186 77.2385 23.3003 77.2385 26.6412C77.2385 29.9214 78.1193 32.5335 79.8809 34.4773C81.6425 36.3604 83.8597 37.3019 86.5325 37.3019C89.1445 37.3019 91.3313 36.3604 93.0929 34.4773C94.9152 32.5942 95.8264 29.9822 95.8264 26.6412Z"
          fill={PRIMARY_DARK}
        />
        <path
          d="M120.946 26.6412C120.946 21.3565 122.04 16.7399 124.226 12.7915C126.413 8.84309 129.45 5.80587 133.338 3.67981C137.286 1.55375 141.781 0.490723 146.823 0.490723C153.323 0.490723 158.79 2.28269 163.224 5.86661C167.659 9.38979 170.514 14.3405 171.789 20.7186H152.837C151.744 17.3777 149.618 15.7072 146.459 15.7072C144.211 15.7072 142.419 16.6488 141.083 18.5318C139.807 20.3542 139.169 23.0573 139.169 26.6412C139.169 30.2252 139.807 32.9587 141.083 34.8417C142.419 36.7248 144.211 37.6664 146.459 37.6664C149.678 37.6664 151.804 35.9959 152.837 32.6549H171.789C170.514 38.9724 167.659 43.9231 163.224 47.507C158.79 51.0909 153.323 52.8829 146.823 52.8829C141.781 52.8829 137.286 51.8198 133.338 49.6938C129.45 47.5677 126.413 44.5305 124.226 40.5821C122.04 36.6337 120.946 31.9868 120.946 26.6412Z"
          fill={PRIMARY_DARK}
        />
        <path
          d="M228.047 26.0945C228.047 27.4917 227.956 28.8888 227.773 30.2859H193.969C194.151 33.0802 194.911 35.1758 196.247 36.573C197.644 37.9093 199.406 38.5775 201.532 38.5775C204.508 38.5775 206.634 37.2412 207.91 34.5684H226.953C226.164 38.0916 224.615 41.2503 222.306 44.0445C220.059 46.778 217.204 48.9345 213.741 50.5138C210.279 52.0932 206.452 52.8829 202.261 52.8829C197.219 52.8829 192.724 51.8198 188.775 49.6938C184.888 47.5677 181.82 44.5305 179.573 40.5821C177.386 36.6337 176.292 31.9868 176.292 26.6412C176.292 21.2957 177.386 16.6791 179.573 12.7915C181.759 8.84309 184.797 5.80587 188.684 3.67981C192.633 1.55375 197.158 0.490723 202.261 0.490723C207.303 0.490723 211.767 1.52338 215.655 3.58869C219.543 5.65401 222.58 8.63049 224.767 12.5181C226.953 16.345 228.047 20.8705 228.047 26.0945ZM209.823 21.6298C209.823 19.443 209.094 17.7422 207.637 16.5273C206.179 15.2516 204.356 14.6138 202.17 14.6138C199.983 14.6138 198.191 15.2213 196.794 16.4362C195.397 17.5903 194.485 19.3215 194.06 21.6298H209.823Z"
          fill={PRIMARY_DARK}
        />
        <g opacity="0.8">
          <path
            d="M140.156 81.9078C140.433 81.4971 140.813 81.1635 141.295 80.9068C141.788 80.6501 142.348 80.5218 142.974 80.5218C143.703 80.5218 144.36 80.7015 144.945 81.0608C145.541 81.4201 146.008 81.9334 146.346 82.6007C146.695 83.2578 146.87 84.0226 146.87 84.8952C146.87 85.7679 146.695 86.543 146.346 87.2205C146.008 87.8878 145.541 88.4063 144.945 88.7759C144.36 89.1454 143.703 89.3302 142.974 89.3302C142.337 89.3302 141.778 89.207 141.295 88.9606C140.823 88.704 140.443 88.3755 140.156 87.9751V89.1916H138V77.7961H140.156V81.9078ZM144.668 84.8952C144.668 84.3819 144.56 83.9405 144.345 83.5709C144.139 83.191 143.862 82.9036 143.513 82.7085C143.174 82.5135 142.805 82.4159 142.404 82.4159C142.014 82.4159 141.645 82.5186 141.295 82.7239C140.957 82.919 140.679 83.2064 140.464 83.5863C140.259 83.9661 140.156 84.4127 140.156 84.926C140.156 85.4393 140.259 85.8859 140.464 86.2658C140.679 86.6456 140.957 86.9382 141.295 87.1435C141.645 87.3386 142.014 87.4361 142.404 87.4361C142.805 87.4361 143.174 87.3334 143.513 87.1281C143.862 86.9228 144.139 86.6302 144.345 86.2504C144.56 85.8705 144.668 85.4188 144.668 84.8952Z"
            fill={PRIMARY_DARK}
          />
          <path
            d="M156.598 80.6604L151.316 93.2263H149.022L150.87 88.976L147.451 80.6604H149.869L152.071 86.6199L154.304 80.6604H156.598Z"
            fill={PRIMARY_DARK}
          />
          <path
            d="M224.485 71.2863C223.676 71.4801 222.981 72.167 222.795 72.9509C222.496 74.2236 223.234 75.4331 224.502 75.7534C225.079 75.9009 225.8 75.8208 226.356 75.5511C226.698 75.3867 227.128 74.9611 227.317 74.6071C227.781 73.7221 227.621 72.6053 226.934 71.9184C226.63 71.6192 226.2 71.379 225.804 71.282C225.471 71.2062 224.818 71.2062 224.485 71.2863Z"
            fill={PRIMARY_DARK}
          />
          <path
            d="M192.57 78.6021C192.583 85.0457 192.591 85.9897 192.65 86.2889C192.975 87.9577 193.755 89.0703 195.065 89.7277C196.228 90.3135 197.876 90.3893 199.326 89.9258C199.697 89.8078 200.493 89.4833 200.641 89.3863C200.687 89.3568 200.598 89.0155 200.207 87.7133C199.937 86.8114 199.709 86.0402 199.697 86.0023C199.68 85.9391 199.654 85.9433 199.456 86.036C199.123 86.1877 198.66 86.2804 198.344 86.2509C197.682 86.1877 197.193 85.7326 197.071 85.0625C197.037 84.8897 197.025 82.7489 197.025 78.0374V71.2608H194.791H192.553L192.57 78.6021Z"
            fill={PRIMARY_DARK}
          />
          <path
            d="M200.83 78.7498C200.843 86.2385 200.843 86.2427 200.931 86.6051C201.357 88.3203 202.352 89.4582 203.873 89.9681C204.994 90.3432 206.385 90.3137 207.653 89.888C208.171 89.7152 208.909 89.3907 208.909 89.3402C208.909 89.3107 208.273 87.1614 208.02 86.3396L207.893 85.9266L207.725 86.0151C207.493 86.1331 206.958 86.2638 206.713 86.2638C206.368 86.2638 205.993 86.1205 205.736 85.8845C205.47 85.6401 205.348 85.3914 205.285 84.97C205.259 84.793 205.243 82.1633 205.243 77.9701V71.261H203.03H200.818L200.83 78.7498Z"
            fill={PRIMARY_DARK}
          />
          <path
            d="M183.518 75.8207C180.678 76.141 178.313 78.0374 177.479 80.6628C177.201 81.531 177.159 81.8428 177.155 82.9765C177.155 83.8867 177.167 84.0637 177.251 84.4852C177.563 86.0318 178.351 87.4225 179.477 88.4171C180.425 89.2557 181.735 89.8836 183.033 90.1196C183.636 90.2292 184.951 90.2545 185.562 90.166C188.01 89.812 189.979 88.4255 191.036 86.3099C192.039 84.3124 192.048 81.767 191.066 79.7526C189.974 77.5232 187.884 76.0988 185.301 75.8165C184.824 75.7617 183.998 75.7659 183.518 75.8207ZM185.174 79.8411C185.659 79.9422 186.034 80.1571 186.43 80.5491C186.818 80.9326 187.05 81.3498 187.214 81.9482C187.345 82.4328 187.345 83.5622 187.21 84.0511C186.877 85.2817 186.076 86.0402 184.917 86.2214C184.542 86.2847 184.049 86.2552 183.661 86.1582C182.743 85.918 182.005 85.1552 181.727 84.1522C181.571 83.5707 181.542 82.6646 181.668 82.1041C181.832 81.354 182.22 80.6923 182.713 80.3215C183.408 79.7989 184.209 79.643 185.174 79.8411Z"
            fill={PRIMARY_DARK}
          />
          <path
            d="M174.964 75.9432C173.708 76.1118 172.768 76.7271 172.237 77.7301L172.081 78.025L172.077 77.0642V76.1076H169.906H167.736V83.1032V90.0989L169.957 90.0904L172.182 90.0778L172.203 86.2639C172.224 82.4584 172.228 82.45 172.321 82.1424C172.633 81.1225 173.248 80.4904 174.205 80.1996C174.799 80.0184 175.65 80.0142 176.401 80.1954C176.603 80.2417 176.776 80.2755 176.784 80.267C176.805 80.2417 177.383 76.1329 177.366 76.116C177.357 76.1076 177.147 76.0654 176.894 76.0191C176.354 75.9264 175.377 75.8842 174.964 75.9432Z"
            fill={PRIMARY_DARK}
          />
          <path
            d="M209.415 78.0883V80.069L212.445 80.0774L215.475 80.09L213.726 81.8811C212.761 82.8672 211.847 83.8239 211.691 84.0093C211.058 84.7594 210.422 85.8467 210.077 86.7696C209.739 87.6757 209.499 88.8852 209.499 89.6859V90.0989H215.652H221.805V88.0971V86.0953H218.577H215.349L218.408 83.0063L221.463 79.9215L221.468 78.0124V76.1076H215.441H209.415V78.0883Z"
            fill={PRIMARY_DARK}
          />
          <path
            d="M222.942 83.6721V90.0989H225.155H227.367V83.6721V77.2454H225.155H222.942V83.6721Z"
            fill={PRIMARY_DARK}
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
};

export default Logo;
