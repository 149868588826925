import { useMemo } from 'react';
import useAuth from './useAuth';
import navConfig from '../layouts/dashboard/NavConfig';

// ----------------------------------------------------------------------

const useNavConfig = () => {
  const { user } = useAuth();

  const permissionBasedNavConfig = useMemo(() => {
    if (user?.isSuperuser) {
      return navConfig.filter(
        (link) =>
          link.title !== 'my assignments' || (link.title === 'my assignments' && user.isDriver)
      );
    }

    return navConfig.filter(({ permissions, children }, index) => {
      if (children && children.length) {
        navConfig[index].children = children.filter(({ permission }) =>
          user?.permissions.includes(permission)
        );
        return navConfig[index].children.length;
      }

      return Object.values(permissions).some((permission) =>
        user?.permissions?.includes(permission)
      );
    });
  }, [user.isDriver, user?.isSuperuser, user?.permissions]);

  return permissionBasedNavConfig;
};

export default useNavConfig;
