import axios from 'axios';

export const API_ENDPOINT = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng'),
  },
});

export const axiosPrivate = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('i18nextLng'),
  },
});

export const mapBoxAPI = axios.create({
  baseURL: 'https://api.mapbox.com',
  headers: {
    'Content-Type': 'application/json',
  },
  params: {
    access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  },
});
