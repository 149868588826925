import React, { FC } from 'react';
// material
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import AccountPopover from './AccountPopover';
import ThemePopover from './ThemePopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
};

const DashboardNavbar: FC<Props> = ({ onOpenSidebar }) => (
  <RootStyle>
    <ToolbarStyle>
      <IconButton
        onClick={onOpenSidebar}
        sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
      >
        <Iconify icon="eva:menu-2-fill" />
      </IconButton>
      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
        <ThemePopover />
        <AccountPopover />
      </Stack>
    </ToolbarStyle>
  </RootStyle>
);

export default DashboardNavbar;
