import React from 'react';
import ConfirmDialog from './components/ConfirmDialog';
import NotistackProvider from './components/NotistackProvider';
import Router from './routes';
import ThemeProvider from './theme';
import { MotionLazyContainer } from './components/animate';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <ProgressBarStyle />
          <ScrollToTop />
          <Router />
          <ConfirmDialog />
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

export default App;
