import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useFormContext } from 'react-hook-form';
import { sentenceCase } from 'change-case';

const useError = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { setError } = useFormContext() || {};

  const validationError = useCallback(
    (key, value) => {
      enqueueSnackbar(sentenceCase(value.toString()), { variant: 'error' });
      setError?.(key, value);
    },
    [enqueueSnackbar, setError]
  );

  const handleError = useCallback(
    (error: AxiosError) => {
      try {
        if (error.name === 'AxiosError') {
          const { status, data } = error.response;
          if (status >= 500) {
            enqueueSnackbar('Something went wrong. please try again!', {
              variant: 'error',
            });
          } else if (status === 404) {
            enqueueSnackbar(error.message, { variant: 'error' });
          } else if (status >= 400) {
            if ((data as any)?.code !== 'token_not_valid') {
              Object.keys(data).forEach((key) => {
                if (Array.isArray(data[key]) || typeof data[key] === 'string') {
                  validationError(key, data[key]);
                }
              });
            }
          }
        } else {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, validationError]
  );

  return { handleError };
};

export default useError;
