import React, { ReactElement } from 'react';
// component
import { PATH_PAGE } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
import { PERMISSIONS } from '../../utils/constants';

// ----------------------------------------------------------------------
const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} />;

const ICONS = {
  Dashboard: getIcon('dashboard'),
  Loads: getIcon('loads'),
  Drivers: getIcon('drivers'),
  Equipment: getIcon('equipment'),
  Customers: getIcon('customers'),
  Settings: getIcon('settings'),
  Assignments: getIcon('assignments'),
};

export interface Nav {
  title: string;
  path: string;
  permissions: Record<string, string>;
  icon: ReactElement;
  children?: NavChildren[];
}

export interface NavChildren {
  title: string;
  path: string;
  permission: string;
}

const navConfig: Nav[] = [
  {
    title: 'dashboard',
    path: PATH_PAGE.dashboard.root,
    icon: ICONS.Dashboard,
    permissions: PERMISSIONS.dashboard,
    children: [
      {
        title: 'operations',
        path: PATH_PAGE.dashboard.operations,
        permission: PERMISSIONS.dashboard.operations,
      },
      {
        title: 'planning',
        path: PATH_PAGE.dashboard.planning,
        permission: PERMISSIONS.dashboard.planning,
      },
      {
        title: 'work items',
        path: PATH_PAGE.dashboard.workitem,
        permission: PERMISSIONS.dashboard.workItem,
      },
    ],
  },
  {
    title: 'my assignments',
    path: PATH_PAGE.driverAssignments.root,
    icon: ICONS.Assignments,
    permissions: PERMISSIONS.myAssignments,
  },
  {
    title: 'loads',
    path: PATH_PAGE.loads.root,
    icon: ICONS.Loads,
    permissions: PERMISSIONS.loads,
    children: [
      {
        title: 'current',
        path: PATH_PAGE.loads.current,
        permission: PERMISSIONS.loads.currentLoadsList,
      },
      {
        title: 'available',
        path: PATH_PAGE.loads.available,
        permission: PERMISSIONS.loads.availableLoadsList,
      },
    ],
  },
  {
    title: 'drivers',
    path: PATH_PAGE.drivers.root,
    icon: ICONS.Drivers,
    permissions: PERMISSIONS.drivers,
  },
  {
    title: 'equipment',
    path: PATH_PAGE.equipment.root,
    icon: ICONS.Equipment,
    permissions: PERMISSIONS.equipment,
  },
  {
    title: 'customers',
    path: PATH_PAGE.customers.root,
    icon: ICONS.Customers,
    permissions: PERMISSIONS.customers,
  },
  {
    title: 'admin',
    path: PATH_PAGE.admin.root,
    icon: ICONS.Settings,
    permissions: { ...PERMISSIONS.customers, ...PERMISSIONS.roles },
    children: [
      {
        title: 'users',
        path: PATH_PAGE.admin.users.root,
        permission: PERMISSIONS.users.list,
      },
      {
        title: 'settings',
        path: PATH_PAGE.admin.roles.root,
        permission: PERMISSIONS.roles.list,
      },
      {
        title: 'uploads',
        path: PATH_PAGE.admin.uploads,
        permission: PERMISSIONS.loads.currentLoadsList
      }
    ],
  },
];

export default navConfig;
