import type { DriverStatusValues, LoadStatusValues } from '../@types/constants';
import { LabelColor } from '../components/Label';
import { ColorSchema } from '../theme/palette';
import { getHoursToContact } from './formatTime';

export const PERMISSIONS = {
  users: {
    read: 'User - Read',
    list: 'User - List',
    create: 'User - Create',
    edit: 'User - Edit',
    delete: 'User - Delete',
    toggleEnable: 'User - Enable/Disable',
  },
  roles: {
    list: 'Role - List',
    read: 'Role - Read',
    create: 'Role - Create',
    edit: 'Role - Edit',
    delete: 'Role - Delete',
  },
  loads: {
    currentLoadsList: 'Current Load - List',
    create: 'Load - Create',
    availableLoadsList: 'Available Load - List',
    edit: 'Load - Edit',
    fileCreate: 'Load File - Create',
    fileList: 'Load File - List',
    fileDelete: 'Load File - Delete',
  },
  equipment: {
    read: 'Equipment - Read',
    list: 'Equipment - List',
    sync: 'Equipment - Sync',
  },
  drivers: {
    list: 'Driver - List',
    read: 'Driver - Read',
  },
  customers: {
    list: 'Customer - List',
    read: 'Customer - Read',
    create: 'Customer - Create',
    edit: 'Customer - Edit',
    delete: 'Customer - Delete',
  },
  dashboard: {
    operations: 'Dashboard - Operations',
    planning: 'Dashboard - Planning',
    workItem: 'Work Item - All',
  },
  notes: {
    list: 'Load Note - List',
    create: 'Load Note - Create',
  },
  myAssignments: {
    root: 'isDriver',
  },
};

export const DRIVER_STATUS_LIST = {
  onDuty: 'On Duty',
  offDuty: 'Off Duty',
  driving: 'Driving',
  personalConveyance: 'Personal Conveyance',
  yardMove: 'Yard Move',
  sleeperBed: 'Sleeper Berth',
} as const;

export const DRIVER_STATUS_COLORS: Record<DriverStatusValues, string> = {
  'On Duty': 'primary',
  'Off Duty': 'secondary',
  Driving: 'info',
  'Personal Conveyance': 'error',
  'Yard Move': 'warning',
  'Sleeper Berth': 'default',
};

export const HOS_THRESHOLD_OPTIONS = [5, 10, 15, 20, 25, 30] as const;

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

export const STATES = [
  {
    region: 'New England',
    state: 'Connecticut',
  },
  {
    region: 'New England',
    state: 'Maine',
  },
  {
    region: 'New England',
    state: 'Massachusetts',
  },
  {
    region: 'New England',
    state: 'New Hampshire',
  },
  {
    region: 'New England',
    state: 'Rhode Island',
  },
  {
    region: 'New England',
    state: 'Vermont',
  },
  {
    region: 'Middle Atlantic',
    state: 'Delaware',
  },
  {
    region: 'Middle Atlantic',
    state: 'Maryland',
  },
  {
    region: 'Middle Atlantic',
    state: 'New Jersey',
  },
  {
    region: 'Middle Atlantic',
    state: 'New York',
  },
  {
    region: 'Middle Atlantic',
    state: 'Pennsylvania',
  },
  {
    region: 'South',
    state: 'Alabama',
  },
  {
    region: 'South',
    state: 'Arkansas',
  },
  {
    region: 'South',
    state: 'Florida',
  },
  {
    region: 'South',
    state: 'Georgia',
  },
  {
    region: 'South',
    state: 'Kentucky',
  },
  {
    region: 'South',
    state: 'Louisiana',
  },
  {
    region: 'South',
    state: 'Mississippi',
  },
  {
    region: 'South',
    state: 'North Carolina',
  },
  {
    region: 'South',
    state: 'South Carolina',
  },
  {
    region: 'South',
    state: 'Tennessee',
  },
  {
    region: 'South',
    state: 'Virginia',
  },
  {
    region: 'South',
    state: 'West Virginia',
  },
  {
    region: 'Mid West',
    state: 'Illinois',
  },
  {
    region: 'Mid West',
    state: 'Indiana',
  },
  {
    region: 'Mid West',
    state: 'Iowa',
  },
  {
    region: 'Mid West',
    state: 'Kansas',
  },
  {
    region: 'Mid West',
    state: 'Michigan',
  },
  {
    region: 'Mid West',
    state: 'Minnesota',
  },
  {
    region: 'Mid West',
    state: 'Missouri',
  },
  {
    region: 'Mid West',
    state: 'Nebraska',
  },
  {
    region: 'Mid West',
    state: 'North Dakota',
  },
  {
    region: 'Mid West',
    state: 'Ohio',
  },
  {
    region: 'Mid West',
    state: 'South Dakota',
  },
  {
    region: 'Mid West',
    state: 'Wisconsin',
  },
  {
    region: 'South West',
    state: 'Arizona',
  },
  {
    region: 'South West',
    state: 'New Mexico',
  },
  {
    region: 'South West',
    state: 'Oklahoma',
  },
  {
    region: 'South West',
    state: 'Texas',
  },
  {
    region: 'West',
    state: 'Alaska',
  },
  {
    region: 'West',
    state: 'California',
  },
  {
    region: 'West',
    state: 'Colorado',
  },
  {
    region: 'West',
    state: 'Hawaii',
  },
  {
    region: 'West',
    state: 'Idaho',
  },
  {
    region: 'West',
    state: 'Montana',
  },
  {
    region: 'West',
    state: 'Nevada',
  },
  {
    region: 'West',
    state: 'Oregon',
  },
  {
    region: 'West',
    state: 'Utah',
  },
  {
    region: 'West',
    state: 'Washington',
  },
  {
    region: 'West',
    state: 'Wyoming',
  },
] as const;

export const PROFILE_PICTURE_MAX_SIZE = 500 * 1024;

export const RATING_OPTIONS = [1, 2, 3, 4, 5].map((opt) => ({
  label: `${opt} Star`,
  value: opt,
}));

export const CUSTOMER_TYPE = ['Shipper', 'Broker'] as const;
export const CUSTOMER_TRACKING_TYPE = [
  'Project 44',
  'Macropoint',
  'Four Kites',
  'Check Call',
  'Mobile App',
] as const;

export const DAYS_TO_CONTACT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] as const;

export const HOURS_TO_CONTACT = getHoursToContact();

export const TIME_ZONES = [
  'Hawaii',
  'Alaska',
  'Pacific',
  'Mountain',
  'Central',
  'Eastern',
] as const;

export const ROLES = ['Sales', 'Operations', 'Manager', 'Finance'] as const;

export const EQUIPMENT_STATUS_LIST = {
  Moving: 'Moving',
  On: 'Engine ON',
  Off: 'Engine OFF',
  Idle: 'Idle',
} as const;

export const EQUIPMENT_TYPE_LIST = ['Semi-Trailer Truck', 'Semi Truck', 'Trailer'] as const;

export const EQUIPMENT_STATUS_COLORS = {
  Moving: 'success',
  Off: 'error',
  On: 'warning',
};

export const VEHICLE_FLEETIO_STATUS_COLORS = {
  Active: 'success',
  Inactive: 'secondary',
  'In Shop': 'warning',
  'Out of Service': 'error',
  Sold: 'primary',
  'Next in Shop': 'info',
};

export const LOAD_STATUS_LIST = {
  hold: 'Hold',
  tendered: 'Tendered',
  planned: 'Planned',
  staged: 'Staged',
  dispatched: 'Dispatched',
  inTransit: 'In Transit',
  delayed: 'Delayed',
  cancelled: 'Cancelled',
  delivered: 'Delivered',
  invoiced: 'Invoiced',
  paid: 'Paid',
};

export const LOAD_STATUS_LIST_ARRAY = Object.values(LOAD_STATUS_LIST);

export const LOAD_BEFORE_DISPATCH = [
  LOAD_STATUS_LIST.hold,
  LOAD_STATUS_LIST.tendered,
  LOAD_STATUS_LIST.planned,
  LOAD_STATUS_LIST.staged,
];

export const LOAD_AFTER_STAGED = [
  LOAD_STATUS_LIST.dispatched,
  LOAD_STATUS_LIST.inTransit,
  LOAD_STATUS_LIST.delayed,
  LOAD_STATUS_LIST.cancelled,
  LOAD_STATUS_LIST.delivered,
  LOAD_STATUS_LIST.invoiced,
  LOAD_STATUS_LIST.paid,
];

export const LOAD_IN_PROCESS = [
  LOAD_STATUS_LIST.dispatched,
  LOAD_STATUS_LIST.inTransit,
  LOAD_STATUS_LIST.delayed,
];

export const STOP_TYPE = [
  'Shipper',
  'Receiver',
  'Truckstop',
  'Non-Truckstop',
  'Fuel Station',
  'Rest Area',
  'Rollzi Yard',
  'Rollzi Terminal',
  'Other',
] as const;

export const STOP_REASON = [
  'Live Pick',
  'Preloaded Pick',
  'Fuel',
  'Break',
  'Layover',
  'Maintenance',
  'Truck Change',
  'Driver Change',
  'Live Unload',
  'Drop Trailer',
] as const;

export type StopType = (typeof STOP_TYPE)[number];
export type StopReason = (typeof STOP_REASON)[number];

export const STOP_REASON_ICONS: Record<StopReason | 'Pickup' | 'Drop', string> = {
  Pickup: 'pick_up',
  Drop: 'drop',
  Fuel: 'fuel',
  Break: 'break',
  Layover: 'layover',
  Maintenance: 'maintenance',
  'Truck Change': 'truck_change',
  'Driver Change': 'driver_change',
  'Live Pick': 'pick_up',
  'Preloaded Pick': 'pick_up',
  'Live Unload': 'drop',
  'Drop Trailer': 'drop',
};

export const AVAILABLE_LOAD_RADIUS = [50, 100, 150, 200, 250, 300, 350, 400, 450].map((opt) => ({
  label: `${opt} miles`,
  value: opt,
}));

export const LOAD_STATUS_COLORS: Record<LoadStatusValues, LabelColor> = {
  Cancelled: 'error',
  Delayed: 'warning',
  Delivered: 'success',
  Dispatched: 'success',
  Invoiced: 'secondary',
  Paid: 'primary',
  Staged: 'info',
  Tendered: 'secondary',
  'In Transit': 'info',
  Planned: 'default',
  Hold: 'default',
};

export const DOCUMENT_TYPE_LIST = [
  'Bill of lading',
  'Invoice',
  'Lumper Receipt',
  'Scale Ticket',
  'Rate Confirmation',
  'Proof of Delivery',
] as const;

export const WORK_ITEM_TYPE = [
  'Mechanical Breakdown',
  'Weather, Fire, Flood',
  'Traffic',
  'Driver',
  'Blocked Pickup',
  'Blocked Dropoff',
  'Other',
] as const;

export const WORK_ITEM_ACTION = [
  'Contact Broker',
  'Contact Maintenance',
  'Contact Facility',
  'Contact Driver',
  'Escalate',
] as const;

export const LOAD_PLANNER_STATUS_COLORS: Record<string, ColorSchema> = {
  'Not Started': 'error',
  Current: 'primary',
  Next: 'secondary',
  Completed: 'success',
};

export const DRIVER_LOAD_PLANNER_STATUS_COLORS: Record<LoadStatusValues, ColorSchema> = {
  Cancelled: 'error',
  Delayed: 'warning',
  Delivered: 'success',
  Dispatched: 'success',
  Invoiced: 'secondary',
  Paid: 'primary',
  Staged: 'info',
  Tendered: 'secondary',
  'In Transit': 'info',
  Planned: 'info',
  Hold: 'info',
};

export const WORKITEM_DOCUMENT_TYPE_LIST = [
  'Repair Receipt',
  'Photo',
  'Scale Ticket',
  'DOT Document',
] as const;

export const CONTACT_ROLE = ['Sales', 'Operations', 'Manager', 'Finance'] as const;

export const DATE_RANGE_OPTIONS = {
  allTime: 'All time',
  today: 'Today',
  thisWeek: 'This Week',
  thisMonth: 'This Month',
  custom: 'Custom',
};

export const LOAD_RATING_STATUS_LIST = [
  {
    label: 'Facility Amenities Rating',
    value: 'facilityAmenitiesRating',
  },
  {
    label: 'Facility Professionalism Rating',
    value: 'facilityProfessionalismRating',
  },
  {
    label: 'Dock Parking Rating',
    value: 'dockParkingRating',
  },
  {
    label: 'Booking Process Rating',
    value: 'bookingProcessRating',
  },
  {
    label: 'Tracking Hassle Rating',
    value: 'trackingHassleRating',
  },
  {
    label: 'Issue Resolution Rating',
    value: 'issueResolutionRating',
  },
  {
    label: 'Pod Upload Rating',
    value: 'podUploadProcessRating',
  },
  {
    label: 'Payment Hassle Rating',
    value: 'paymentHassleRating',
  },
];
export const STOP_DELAY_HOURS: Record<StopReason, { hours: number; minutes: number }> = {
  Fuel: {
    hours: 0,
    minutes: 25,
  },
  Break: {
    hours: 0,
    minutes: 50,
  },
  Layover: {
    hours: 10,
    minutes: 0,
  },
  Maintenance: {
    hours: 4,
    minutes: 0,
  },
  'Truck Change': {
    hours: 0,
    minutes: 10,
  },
  'Driver Change': {
    hours: 0,
    minutes: 25,
  },
  'Live Pick': {
    hours: 2,
    minutes: 0,
  },
  'Preloaded Pick': {
    hours: 2,
    minutes: 0,
  },
  'Live Unload': {
    hours: 2,
    minutes: 0,
  },
  'Drop Trailer': {
    hours: 2,
    minutes: 0,
  },
};

export const ISSUE_STATUS_COLORS = {
  Resolved: 'success',
  Closed: 'error',
  Open: 'primary',
};

export const LOAD_STATUS_BEFORE_DISPATCHED = [
  LOAD_STATUS_LIST.hold,
  LOAD_STATUS_LIST.tendered,
  LOAD_STATUS_LIST.planned,
  LOAD_STATUS_LIST.staged,
];

export const LOAD_STATUS_AFTER_STAGED = [
  LOAD_STATUS_LIST.inTransit,
  LOAD_STATUS_LIST.dispatched,
  LOAD_STATUS_LIST.delayed,
  LOAD_STATUS_LIST.delivered,
  LOAD_STATUS_LIST.invoiced,
  LOAD_STATUS_LIST.paid,
];
export const AVAILABLE_LOAD_SOURCE = ['All', 'Uber', 'Ch Robinson'] as const;

export const ACCESSORIAL_CHARGES_TYPES = [
  'Detention',
  'Lumper Fee',
  'Rework',
  'Fuel Surcharge',
  'Layover',
  'Late Paperwork',
  'Late Appointment',
  'Quickpay',
];
