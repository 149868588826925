import React, { FC } from 'react';
import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets';
import { Navigate, useLocation } from 'react-router-dom';
import { PATH_PAGE } from '../routes/paths';

// ----------------------------------------------------------------------

type Props = {
  redirect?: boolean;
  permission: string;
  children: React.ReactNode;
  hide?: boolean;
};

const RoleBasedGuard: FC<Props> = ({ redirect, permission, children, hide }) => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const permissions: string[] = user?.permissions || [];
  const isSuperUser = !!user?.isSuperuser;

  if (!isSuperUser && !permissions.includes(permission)) {
    return redirect ? (
      <Navigate to={PATH_PAGE.permissionDenied} replace state={{ from: pathname }} />
    ) : hide ? null : (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    );
  }

  return <>{children}</>;
};

export default RoleBasedGuard;
