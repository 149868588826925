import { useContext } from 'react';
import { SettingsContext } from '../contexts/SettingsContext';

// ----------------------------------------------------------------------

const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) throw new Error('Settings context must be used inside SettingsProvider');

  return context;
};

export default useSettings;
