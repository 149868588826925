import {
  format,
  formatDistanceToNow,
  hoursToMinutes,
  hoursToSeconds,
  minutesToSeconds,
  secondsToHours,
  secondsToMinutes,
} from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeAmpm(date, divider = ' ') {
  if (!date) return null;
  return format(new Date(date), `dd MMM yyyy${divider}hh:mm aa`);
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTimeAPI(date) {
  return format(new Date(date), "yyyy-MM-dd'T'HH:mm:ssxxx");
}

export function fDateAPI(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateUS(date) {
  return format(new Date(date), 'MM-dd-yyyy');
}

export function fDateTimeUS(date) {
  return format(new Date(date), 'MM-dd-yyyy HH:mm');
}

export function fTimeUS(date) {
  return format(new Date(date), 'HH:mm');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fMsToHours(timeInMs: number) {
  if (timeInMs === 0) return '00:00';
  const minutes = Math.abs(Math.floor(timeInMs / 1000 / 60) % 60).toString();
  const hours = Math.abs(Math.floor(timeInMs / 1000 / 60 / 60)).toString();
  return `${hours.length < 2 ? 0 + hours : hours}:${minutes.length < 2 ? 0 + minutes : minutes}`;
}

export const fSecondsToHours = (seconds: number) => {
  const hours = secondsToHours(seconds);
  const minutes = secondsToMinutes(seconds) - hoursToMinutes(hours);
  if (hours) {
    return `${hours} hrs ${minutes} mins`;
  }
  return `${minutes} mins`;
};

export const getHoursToContact = () => {
  const getH = (number: number) => `0${number === 0 ? 12 : number}`.slice(-2);
  const getAm = (len: number, index: number, am: string) =>
    index === len - 1 ? (am === 'AM' ? 'PM' : 'AM') : am;
  const slots = [...new Array(12)].fill('').reduce<string[]>((acc, _, i) => {
    acc.push(`${getH(i)}:00`);
    acc.push(`${getH(i)}:30`);
    return acc;
  }, []);
  return ['AM', 'PM'].reduce<string[]>((acc, am) => {
    acc = [...acc, ...slots.map((slot, index) => `${slot} ${getAm(slots.length, index, am)}`)];
    return acc;
  }, []);
};

export const convertSecondsToHMS = (secondValue: number) => {
  const data = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  if (!(secondValue && secondValue > 0)) return data;
  const hours = secondsToHours(secondValue);
  const minutes = secondsToMinutes(secondValue) - hoursToMinutes(hours);
  const seconds = secondValue - minutesToSeconds(minutes) - hoursToSeconds(hours);
  return {
    hours,
    minutes,
    seconds,
  };
};

export const convertHMSToSeconds = ({ hours = 0, minutes = 0, seconds = 0 }) =>
  hoursToSeconds(hours) + minutesToSeconds(minutes) + seconds;

export const toHoursMinutesSeconds = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { h: hours, m: minutes, s: seconds };
};

export const fSecondsToHoursWithHrandM = (seconds: number) => {
  const hours = secondsToHours(seconds);
  const minutes = secondsToMinutes(seconds) - hoursToMinutes(hours);
  if (hours) {
    return `${hours}hr ${minutes}m`;
  }
  return `${minutes} m`;
};
