import React, { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_PAGE } from '../routes/paths';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

const GuestGuard: FC<Props> = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={PATH_PAGE.dashboard.root} replace />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

export default GuestGuard;
