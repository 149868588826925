import { useMemo } from 'react';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { getJsonToFormData } from '../utils/getFileData';
import axios from './axios';

const useAuthApis = () => {
  const axiosPrivate = useAxiosPrivate();
  const { logout } = useAuth();

  const apis = useMemo(
    () => ({
      login: (data) =>
        axios.post('auth/token', data).then((response) => {
          localStorage.setItem('refresh', response.data.refresh);
          localStorage.setItem('access', response.data.access);
          return response;
        }),
      ssoLogin: (token:string) =>
        axios.post('auth/sso', { token }).then((response) => {
          localStorage.setItem('refresh', response.data.refresh);
          localStorage.setItem('access', response.data.access);
          return response;
        }),

      forgotPassword: (data) => axios.post('/auth/request-password-reset', data),
      passwordReset: (data) => axios.post('/auth/password-reset', data),
      logout: () => {
        const data = { refreshToken: localStorage.getItem('refresh') };
        return axiosPrivate.post('/auth/logout', data).finally(() => logout('Manual'));
      },
      getMyProfile: () => axiosPrivate.get('/me'),
      updateMyProfile: (data) =>
        axiosPrivate.patch('/me', getJsonToFormData(data), {
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
      changePassword: (data) => axiosPrivate.post('/auth/change-password', data),
      patchSettings: (data) => axiosPrivate.patch(`/auth/user-preferences`, data),
      firstTimePasswordReset: (data) => axiosPrivate.post('/auth/first-time-password-reset', data),
      getLocations: () => axiosPrivate.get('/locations'),
    }),
    [axiosPrivate, logout]
  );

  return apis;
};
export default useAuthApis;
