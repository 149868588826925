import { useState, useEffect, useCallback } from 'react';

// ----------------------------------------------------------------------

const useLocalStorage = <T>(
  key: string,
  defaultValue: T
): [T, (n: T | ((v: T) => void)) => void] => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue === null ? defaultValue : JSON.parse(storedValue);
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = useCallback(
    (newValue: T | ((v: T) => void)) => {
      setValue((currentValue: any) => {
        const result =
          typeof newValue === 'function' ? (newValue as any)?.(currentValue) : newValue;

        localStorage.setItem(key, JSON.stringify(result));

        return result;
      });
    },
    [key]
  );

  return [value, setValueInLocalStorage];
};

export default useLocalStorage;
