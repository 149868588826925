// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[isLight ? 600 : 500],
          borderRadius: theme.shape.borderRadius / 2,
        },
        arrow: {
          color: theme.palette.grey[isLight ? 600 : 500],
        },
      },
    },
  };
}
