import { useEffect } from 'react';
import { axiosPrivate } from '../services/axios';
import refresh from '../services/refresh';
import useAuth from './useAuth';

const useAxiosPrivate = () => {
  const { logout } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers.Authorization) {
          const accessToken = localStorage.getItem('access');
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    if (!logout) return;

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest.sent) {
          prevRequest.sent = true;
          try {
            const newAccessToken = await refresh();
            prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return await axiosPrivate(prevRequest);
          } catch {
            logout('Auto');
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [logout]);

  return axiosPrivate;
};

export default useAxiosPrivate;
