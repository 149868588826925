import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Location } from '../@types/location';

export interface LookupsState {
  locations: Location[];
}

const initialState: LookupsState = {
  locations: [],
};

export const lookupsSlice = createSlice({
  name: 'lookups',
  initialState,
  reducers: {
    setLocations: (state, action: PayloadAction<Location[]>) => {
      state.locations = action.payload;
    },
  },
});

export const { setLocations } = lookupsSlice.actions;

export default lookupsSlice.reducer;
