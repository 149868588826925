import axios from './axios';

const refresh = async () => {
  const response = await axios.post('/auth/refresh-token', {
    refresh: localStorage.getItem('refresh') || '',
  });
  localStorage.setItem('access', response.data.access);
  return response.data.access;
};

export default refresh;
