// ----------------------------------------------------------------------

export default function MenuItem(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: theme.palette.common.black,
          },
        },
      },
    },
  };
}
