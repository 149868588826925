// components
import { isArray, isObject } from 'lodash';
import { CustomFile } from '../components/upload/type';

// ----------------------------------------------------------------------

export default function getFileData(file: CustomFile | string, index?: number) {
  if (typeof file === 'string') {
    return {
      key: index ? `${file}-${index}` : file,
      preview: file,
    };
  }

  return {
    key: index ? `${file.name}-${index}` : file.name,
    name: file.name,
    size: file.size,
    path: file.path,
    type: file.type,
    preview: file.preview,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
  };
}

export const getJsonToFormData = (jsonData) => {
  let formData = new FormData();
  for (const key in jsonData) {
    if (jsonData[key] instanceof File) {
      formData.append(key, jsonData[key]);
    } else if (Array.isArray(jsonData[key]) && jsonData[key].every((i) => typeof i === 'string')) {
      formData.append(key, jsonData[key].join(','));
    } else if (isArray(jsonData[key])) {
      for (const childkey in jsonData[key]) {
        formData.append(key, jsonData[key][childkey]);
      }
    } else if (isObject(jsonData[key])) {
      for (const childkey in jsonData[key]) {
        if (Array.isArray(jsonData[key][childkey])) {
          jsonData[key][childkey].forEach((element) => {
            formData.append(`${key}.${childkey}`, element);
          });
        } else {
          formData.append(`${key}.${childkey}`, jsonData[key][childkey]);
        }
      }
    } else {
      formData.append(key, jsonData[key]);
    }
  }
  return formData;
};
