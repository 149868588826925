import { useTranslation } from 'react-i18next';
// config
import { allLangs, defaultLang } from '../config';
import { useCallback } from 'react';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const langStorage = localStorage.getItem('i18nextLng');

  const currentLanguage = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const onLanguageChange = useCallback(
    (newlang: string) => {
      i18n.changeLanguage(newlang);
    },
    [i18n]
  );

  return {
    onLanguageChange,
    translate,
    currentLanguage,
    allLanguages: allLangs,
  };
}
