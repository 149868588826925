import React, { useCallback, createContext, ReactNode, FC } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { allLangs, themeMode } from '../config';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

type Mode = typeof themeMode[number]['value'];
type Language = typeof allLangs[number]['value'];

export type Settings = {
  mode: Mode;
  language: Language;
};

type SettingsContextValues = {
  setThemeMode: (mode: Mode) => void;
  setLanguage: (language: Language) => void;
} & Settings;

const initialState: Settings = {
  mode: themeMode[0].value,
  language: allLangs[0].value,
};

const SettingsContext = createContext<SettingsContextValues>({} as SettingsContextValues);

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

const SettingsProvider: FC<Props> = ({ children }) => {
  const [settings, setSettings] = useLocalStorage<Settings>('settings', initialState);
  const { onLanguageChange } = useLocales();

  const setThemeMode = useCallback(
    (mode: Mode) => {
      setSettings((prev) => ({ ...prev, mode }));
    },
    [setSettings]
  );

  const setLanguage = useCallback(
    (language: Language) => {
      setSettings((prev) => ({ ...prev, language }));
      onLanguageChange(language);
    },
    [onLanguageChange, setSettings]
  );

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        setLanguage,
        setThemeMode,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsProvider, SettingsContext };
