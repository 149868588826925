import React, { FC, ReactNode, useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
  ThemeOptions,
} from '@mui/material/styles';

//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import { themeMode } from '../config';
import useSettings from '../hooks/useSettings';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

const ThemeProvider: FC<Props> = ({ children }) => {
  const { mode: themeLocal } = useSettings();
  const { currentLanguage } = useLocales();
  const isLight = themeLocal === themeMode[0].value;
  const themeOptions = useMemo(
    () =>
      ({
        palette: isLight ? palette.light : palette.dark,
        typography,
        breakpoints,
        shape: { borderRadius: 8 },
        shadows: isLight ? shadows.light : shadows.dark,
        customShadows: isLight ? customShadows.light : customShadows.dark,
      } as ThemeOptions),
    [isLight]
  );

  const theme = createTheme(themeOptions, currentLanguage.systemValue);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
