export const PATH_PAGE = {
  root: '/',
  permissionDenied: '/permission-denied',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  login: '/login',
  verifyCode: '/verify-code',
  forgotPassword: '/forgot-password',
  operations: '/operations',
  planning: '/planning',
  loads: {
    root: '/loads',
    available: '/loads/available',
    current: '/loads/current',
    new: '/loads/current/new',
    upload: '/loads/current/upload',
    edit: (id: string) => `/loads/current/${id}/edit`,
  },
  customers: {
    root: '/customers',
    new: '/customers/new',
    edit: (id: string) => `/customers/${id}/edit`,
  },
  drivers: {
    root: '/drivers',
    new: 'drivers/new',
    view: (id: string) => `/drivers/${id}`,
    edit: (id: string) => `/drivers/${id}/edit`,
  },
  equipment: {
    root: '/equipment',
    edit: (id: string) => `/equipment/${id}/edit`,
    view: (id: string) => `/equipment/${id}`,
  },
  dashboard: {
    root: '/dashboard',
    operations: '/dashboard/operations',
    planning: '/dashboard/planning',
    workitem: '/dashboard/workitem',
  },
  profile: '/profile',
  admin: {
    root: '/admin',
    roles: {
      root: '/admin/roles',
      new: '/admin/roles/new',
      edit: (id: string) => `/admin/roles/${id}/edit`,
    },
    users: {
      root: '/admin/users',
      new: '/admin/users/new',
      edit: (id: string) => `/admin/users/${id}/edit`,
    },
    uploads: '/admin/uploads'
  },
  passwordReset: '/password-reset',
  driverAssignments: {
    root: '/driver-assignments',
    view: (id: string) => `/driver-assignments/${id}`,
  },
};
