import { AxiosResponse } from 'axios';
import React, { createContext, FC, useCallback, useEffect, useReducer } from 'react';
import type { ActionMap, AuthState, JWTContextType, LogoutType } from '../@types/auth';
import type { Permission } from '../@types/permission';
import type { AuthUser } from '../@types/user';
import LoadingScreen from '../components/LoadingScreen';
import useSettings from '../hooks/useSettings';
import refresh from '../services/refresh';
import useAuthApis from '../services/useAuthApis';
import { PERMISSIONS } from '../utils/constants';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    logoutType?: 'Auto';
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: {
    type?: string;
  };
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  logoutType: '',
};

// ----------------------------------------------------------------------

const addPermissionListToUser = (user: AuthUser & { permissions: Permission[] }): AuthUser => ({
  ...user,
  permissions: user?.role.length
    ? [
        ...[...user?.role.map((role) => role.permissions).flat()].map(
          ({ name, category }) => `${category} - ${name}`
        ),
        ...(user?.isDriver ? [PERMISSIONS.myAssignments.root] : []),
      ]
    : [],
});

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        logoutType: action.payload.logoutType,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        logoutType: action.payload.type,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType>({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: null,
});

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

const AuthProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { getMyProfile } = useAuthApis();
  const { setThemeMode } = useSettings();

  const getUserDetails = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('access');

      if (accessToken) {
        let response: AxiosResponse;
        try {
          response = await getMyProfile();
        } catch (error) {
          await refresh();
          response = await getMyProfile();
        }

        const user = addPermissionListToUser(response.data);

        dispatch({
          type: Types.Initial,
          payload: {
            user,
            isAuthenticated: true,
          },
        });

        if (user?.preferences?.theme) {
          setThemeMode(user?.preferences?.theme);
        }
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
          logoutType: 'Auto',
        },
      });
    }
  }, [getMyProfile, setThemeMode]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  const login = useCallback(async () => {
    try {
      const response = await getMyProfile();
      const user = addPermissionListToUser(response.data);

      dispatch({
        type: Types.Login,
        payload: {
          user,
        },
      });

      if (user?.preferences?.theme) {
        setThemeMode(user?.preferences?.theme);
      }
    } catch (err) {
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [getMyProfile, setThemeMode]);

  const logout = useCallback(async (type: LogoutType) => {
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
    dispatch({ type: Types.Logout, payload: { type } });
  }, []);

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        logoutType: '',
        method: 'jwt',
        login,
        logout,
        ...state,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
